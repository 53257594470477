import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module'

import Login from './pages/Login/Login';
import SingleThreatDetail from './pages/ThreadsDetails/SingleThreatDetail';
import TakedownList from './pages/TakedownList/TakedownList';
import CompaniesList from './pages/CompaniesList/CompaniesList';
import CompanyDetails from './pages/CompanyDetails/CompanyDetails';
import ActiveDefense from './pages/ActiveDefense/ActiveDefense';
import FalsePositive from './pages/FalsePositive/FalsePositive';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import EnrichTakedown from './pages/TakedownList/EnrichTakedown';
import DefaultLayout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import TakedownStatusHistory from './pages/TakedownList/TakedownStatusHistory';
import useApiInterceptor from './helpers/useApiInterceptor';
import GSBHistory from './pages/GSBHistory/GSBHistory';
import {
  CHANGE_BULK_STATUS_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
} from './store/constant';
import { showToast } from './store/toaster/action';
import {
  REACT_APP_BASE_URL_WEBSOCKET,
  REACT_APP_ENVIRONMENT,
  REACT_APP_GTM_ID,
  REACT_APP_SENTRY_DSN,
} from './utils/envVar';

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const tagManagerArgs: any = {
  gtmId: REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)

function App() {
  const dispatch = useDispatch();
  useApiInterceptor();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    let ws;
    const RECONNECT_INTERVAL = 5000;
    const connect = async () => {
      const token: any = await getIdTokenClaims();
      const user = {
        email: token?.email,
        name: token?.name,
      };
      Sentry.setUser(user);
        (window as any).dataLayer.push({
          event: 'appInit',
          userName: user.name,
          userEmail: user.email,
        });
      ws = new WebSocket(
        `${REACT_APP_BASE_URL_WEBSOCKET}?bearer=${token?.__raw}`,
      );

      ws.onmessage = function (event) {
        console.log(event.data);
        const data = JSON.parse(event.data);
        if (data?.error) {
          dispatch(showToast(data.error || 'Something want wrong', 'error'));
          dispatch({
            type: CHANGE_BULK_STATUS_FAILURE,
            payload: data,
          });
        } else {
          dispatch(
            showToast(
              `${data.threat_name} status has been changed to ${data.status}`,
              'success',
            ),
          );
          dispatch({
            type: CHANGE_STATUSLABEL_SUCCESS,
            payload: data,
          });
        }
      };

      ws.onclose = function () {
        setTimeout(connect, RECONNECT_INTERVAL);
      };
    };
    if (isAuthenticated) {
      connect();
    }
  }, [isAuthenticated, getIdTokenClaims]);

  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute element={<Login />} />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <Home />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/threats/customer-review-requests"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <Home />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="threats/:threatName/:domainName/:companyId"
            element={<SingleThreatDetail />}
          />
          <Route
            path="/takedown"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <Outlet />
                  </DefaultLayout>
                }
              />
            }>
            <Route index element={<TakedownList />} />
            <Route
              path="history/:takedown_id"
              element={<TakedownStatusHistory />}
            />
          </Route>
          <Route
            path="/enrich-takedown"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <EnrichTakedown />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <CompaniesList />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies/:id/view"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <CompanyDetails />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/network-disruption"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ActiveDefense />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/false-positive"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <FalsePositive />
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/gsb-history"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <GSBHistory />
                  </DefaultLayout>
                }
              />
            }
          />
        </Routes>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

export default App;
