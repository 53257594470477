/* eslint-disable no-case-declarations */
import { BulkStatusItem, ThreatTypes } from './../../helpers/types';
import {
  CHANGE_BULK_STATUS_BEGIN,
  CHANGE_BULK_STATUS_FAILURE,
  CHANGE_BULK_STATUS_SUCCESS,
  CHANGE_STATUSLABEL_BEGIN,
  CHANGE_STATUSLABEL_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
  CREATE_THREAT_BEGIN,
  CREATE_THREAT_FAILURE,
  CREATE_THREAT_SUCCESS,
  GET_DOMAINS_LIST_BEGIN,
  GET_DOMAINS_LIST_FAILURE,
  GET_DOMAINS_LIST_SUCCESS,
  GET_STATUS_BEGIN,
  GET_STATUS_FAILURE,
  GET_STATUS_SUCCESS,
  GET_THREAT_BEGIN,
  GET_THREAT_DETAIL_BEGIN,
  GET_THREAT_DETAIL_FAILURE,
  GET_THREAT_DETAIL_SUCCESS,
  GET_THREAT_FAILURE,
  GET_THREAT_SUCCESS,
  RESET_THREAT_LIST,
  SET_PAGE_SIZE_PERSIST_VALUES,
  SET_REVIEW_STATUS_PERSIST_VALUES,
  SET_STATUS_PERSIST_VALUES,
  TOGGLE_INFINITE_SCROLL,
} from '../constant';
import {
  defaultReviewStatusFilter,
  defaultStatusFilter,
  PER_PAGE_RECORDS,
} from '../../utils';

const initialState = {
  isInfiniteScroll: false,
  threatList: {
    loading: false,
    items: [],
    error: null,
  },
  threatDetail: {
    loading: false,
    data: [],
    error: null,
  },
  createThreat: {
    loading: false,
    data: [],
    error: null,
  },
  domainList: {
    loading: false,
    data: [],
    error: null,
  },
  getStatus: {
    loading: false,
    data: [],
    error: null,
  },
  statusPersistValues: defaultStatusFilter,
  reviewStatusPersistValues: defaultReviewStatusFilter,
  pageSizePersistValues: PER_PAGE_RECORDS,
};

export const threatReducer = (state = initialState, action: any) => {
  let threatUniqueId: any;
  if (action?.payload?.companyId) {
    const { companyId, threatId, domainId } = action.payload;
    threatUniqueId = `${companyId}${domainId}${threatId}`;
  }
  switch (action.type) {
    case RESET_THREAT_LIST:
      return {
        ...state,
        threatList: { Threats: [] },
      };
    case GET_THREAT_BEGIN:
      return {
        ...state,
        threatList: { ...state.threatList, loading: true, error: null },
      };
    case GET_THREAT_SUCCESS:
      return {
        ...state,
        threatList: {
          ...action.payload,
          loading: false,
        },
      };
    case GET_THREAT_FAILURE:
      return {
        ...state,
        threatList: {
          ...state.threatList,
          loading: false,
          error: action.payload,
        },
      };
    case TOGGLE_INFINITE_SCROLL:
      return {
        ...state,
        isInfiniteScroll: action.payload,
      };
    case CHANGE_STATUSLABEL_BEGIN:
      return {
        ...state,
        threatList: {
          ...state.threatList,
        },
      };
    case CHANGE_STATUSLABEL_SUCCESS:
      const uniqueIdToRemove = `${action.payload.company_id}${action.payload.protected_asset_id}${action.payload.threat_id}`;
      const updatedItems = state.threatList.items.filter(
        (threat: ThreatTypes) => {
          const uniqueId = `${threat.company_id}${threat.protected_asset_id}${threat.threat_id}`;
          return uniqueId !== uniqueIdToRemove;
        },
      );

      return {
        ...state,
        threatList: {
          ...state.threatList,
          items: updatedItems,
        },
      };
    case CHANGE_STATUSLABEL_FAILURE:
      return {
        ...state,
        threatList: {
          ...state.threatList,
          loading: false,
          error: action.error,
        },
      };
    case CHANGE_BULK_STATUS_BEGIN:
      return {
        ...state,
        threatList: {
          ...state.threatList,
        },
      };
    case CHANGE_BULK_STATUS_SUCCESS:
      const bulkThreats: ThreatTypes[] = state?.threatList?.items;
      const updatedThreats = bulkThreats.map(threat => {
        const foundItem = action.payload.find(
          (item: any) => item.threat_name === threat.threat_name,
        );
        if (foundItem) {
          return {
            ...threat,
            isLoading: true,
          };
        }
        return threat;
      });
      return {
        ...state,
        threatList: {
          ...state.threatList,
          items: updatedThreats,
        },
      };
    case CHANGE_BULK_STATUS_FAILURE:
      const updatedThreat = state?.threatList?.items?.map((item: any) =>
        item.threat_name === action.payload.threat_name
          ? { ...item, isLoading: false }
          : item,
      );

      return {
        ...state,
        threatList: {
          ...state.threatList,
          items: updatedThreat || [],
          loading: false,
          error: action.error || null,
        },
      };
    case GET_THREAT_DETAIL_BEGIN:
      return {
        ...state,
        threatDetail: {
          ...state.threatDetail,
          loading: true,
        },
      };
    case GET_THREAT_DETAIL_SUCCESS:
      return {
        ...state,
        threatDetail: {
          ...state.threatDetail,
          loading: false,
          data: action.payload,
        },
      };
    case GET_THREAT_DETAIL_FAILURE:
      return {
        ...state,
        threatDetail: {
          ...state.threatDetail,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_THREAT_BEGIN:
      return {
        ...state,
        createThreat: {
          ...state.createThreat,
          loading: true,
        },
      };
    case CREATE_THREAT_SUCCESS:
      return {
        ...state,
        createThreat: {
          ...state.createThreat,
          loading: false,
          data: action.payload,
        },
      };
    case CREATE_THREAT_FAILURE:
      return {
        ...state,
        createThreat: {
          ...state.createThreat,
          loading: false,
          error: action.payload,
        },
      };
    case GET_DOMAINS_LIST_BEGIN:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          loading: true,
        },
      };
    case GET_DOMAINS_LIST_SUCCESS:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          loading: false,
          data: action.payload,
        },
      };
    case GET_DOMAINS_LIST_FAILURE:
      return {
        ...state,
        domainList: {
          ...state.domainList,
          loading: false,
          error: action.payload,
        },
      };
    case SET_STATUS_PERSIST_VALUES:
      return {
        ...state,
        statusPersistValues: action.payload,
      };
    case SET_REVIEW_STATUS_PERSIST_VALUES:
      return {
        ...state,
        reviewStatusPersistValues: action.payload,
      };
    case SET_PAGE_SIZE_PERSIST_VALUES:
      return {
        ...state,
        pageSizePersistValues: action.payload,
      };
    case GET_STATUS_BEGIN:
      return {
        ...state,
        getStatus: {
          ...state.getStatus,
          loading: true,
        },
      };
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        getStatus: {
          ...state.getStatus,
          loading: false,
          data: action.payload,
        },
      };
    case GET_STATUS_FAILURE:
      return {
        ...state,
        getStatus: {
          ...state.getStatus,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
