import { useState } from 'react';
import {
  createNetworkByType,
  getNetworkDataByType,
  getNetworkTaskById,
} from '../store/singleThreat';
import { isRecentNetworkRecords } from '.';
import { ThreatNetworkData, UseThreatResult } from '../helpers/types';

const useCustomThreatHook = (): UseThreatResult => {
  const [threatNetworkData, setThreatNewtworkData] =
    useState<ThreatNetworkData>({});

  const createNetworkDataByType = async (
    type: 'whois' | 'certificate',
    items: any,
  ) => {
    try {
      const createdWhois = await createNetworkByType({
        asset_id: items?.threat_id,
        type,
      });

      if (createdWhois && createdWhois?.data?.status !== 'FAILURE') {
        setTimeout(async () => {
          const taskId = createdWhois?.data?.task_id;
          const resTaskById = await getNetworkTaskById(taskId);
          const taskData = resTaskById?.data?.items?.[0]?.data || null;

          setThreatNewtworkData(prevData => ({
            ...prevData,
            [items?.threat_id]: {
              ...prevData[items?.threat_id],
              [type]: taskData,
              [`${type}Loading`]: false,
            },
          }));
        }, 3000);
      } else {
        setThreatNewtworkData(prevData => ({
          ...prevData,
          [items?.threat_id]: {
            ...prevData[items?.threat_id],
            [type]: null,
            [`${type}Loading`]: false,
          },
        }));
      }
    } catch (error) {
      setThreatNewtworkData(prevData => ({
        ...prevData,
        [items?.threat_id]: {
          ...prevData[items?.threat_id],
          [type]: null,
          [`${type}Loading`]: false,
        },
      }));
    }
  };

  const fetchNetworkRecords = async (
    type: 'whois' | 'certificate',
    items: any,
  ) => {
    const { threat_id: threatId } = items;
    try {
      setThreatNewtworkData(prevData => ({
        ...prevData,
        [threatId]: {
          ...prevData[threatId],
          [`${type}Loading`]: true,
        },
      }));

      const result = await getNetworkDataByType(threatId, type);

      const isDataExists =
        result?.data?.items.length > 0 &&
        isRecentNetworkRecords(type, result?.data?.items?.[0].created_at);

      if (isDataExists) {
        setThreatNewtworkData(prevData => ({
          ...prevData,
          [threatId]: {
            ...prevData[threatId],
            [type]: result.data?.items?.[0].data || null,
            [`${type}Loading`]: false,
          },
        }));
      } else {
        createNetworkDataByType(type, items);
      }
    } catch (error) {
      setThreatNewtworkData(prevData => ({
        ...prevData,
        [threatId]: {
          ...prevData[threatId],
          [type]: null,
          [`${type}Loading`]: false,
        },
      }));
      console.error(error);
    }
  };

  return {
    threatNetworkData,
    fetchNetworkRecords,
  };
};

export default useCustomThreatHook;
